import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { TextilePurchaseOrdeItemIList, TextilePurchaseOrdeItemIById,TextilePurchaseOrdeItemIAdd, TextilePurchaseOrdeItemIEdit, TextilePurchaseOrdeItemIDel} from "@/api/Purchase/TextilePurchaseOrdeItemApi";

//显示采购订单及明细列表查询
export const PoAndItems = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPoAndItems",
        method: "get",
        params: {
           ...row
        }
    })
}

//显示列表查询
export const TextilePurchaseOrdeItemIList = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetTextilePurchaseOrdeItems",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const TextilePurchaseOrdeItemIById = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetTextilePurchaseOrdeItem",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const TextilePurchaseOrdeItemIAdd = (row) => {
    console.log("3",row,textileBaseUrl);
    return request({
        url: textileBaseUrl + "purchase/Purchase/PostTextilePurchaseOrdeItem",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const TextilePurchaseOrdeItemIEdit = (row) => {
    console.log("row",row);
    return request({
        url: textileBaseUrl + "purchase/Purchase/PutTextilePurchaseOrdeItem",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const TextilePurchaseOrdeItemIDel = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/DeleteTextilePurchaseOrdeItem",
        method: "delete",
        params: {
            id
        }
    })
}



