import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';
import { Console } from "console";

// 引入使用    import { TextilePurchaseOrderIList, TextilePurchaseOrderIById,TextilePurchaseOrderIAdd, TextilePurchaseOrderIEdit, TextilePurchaseOrderIDel} from "@/api/Purchase/TextilePurchaseOrderApi";
//获取物料平均单价
export const PoAvgMaterialPrices = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPoAvgMaterialPrices",
        method: "get",
        params: {
           ...row
        }
    })
}

//显示列表查询
export const TextilePurchaseOrderIList = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetPOs",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const TextilePurchaseOrderIById = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/GetTextilePurchaseOrder",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const TextilePurchaseOrderIAdd = (row) => {
    console.log("3",row,textileBaseUrl);
    return request({
        url: textileBaseUrl + "purchase/Purchase/PostTextilePurchaseOrder",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const TextilePurchaseOrderIEdit = (row) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/PutTextilePurchaseOrder",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const TextilePurchaseOrderIDel = (id) => {
    return request({
        url: textileBaseUrl + "purchase/Purchase/DeleteTextilePurchaseOrder",
        method: "delete",
        params: {
            id
        }
    })
}



