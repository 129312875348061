var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(
                    this.routeSign == "add"
                      ? _vm.$t("ADD_PURCHASE_ORDER")
                      : _vm.$t("EDIT_PURCHASE_ORDER")
                  )
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "refForm",
                    attrs: {
                      model: _vm.formObj,
                      rules: _vm.formObjRules,
                      "label-position": "top",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v(_vm._s(_vm.$t("Seller")))]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Vendor_Section"),
                                  prop: "VendorSectionId",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.VendorSectionId
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_VendorSectionId",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "",
                                      disabled: _vm.isEditLoad
                                    },
                                    model: {
                                      value: _vm.formObj.VendorSectionId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formObj,
                                          "VendorSectionId",
                                          $$v
                                        )
                                      },
                                      expression: "formObj.VendorSectionId"
                                    }
                                  },
                                  _vm._l(_vm.testData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Supplier_"),
                                  prop: "Supplier",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.Supplier
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_Supplier",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "",
                                      disabled: _vm.isEditLoad
                                    },
                                    model: {
                                      value: _vm.formObj.Supplier,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formObj, "Supplier", $$v)
                                      },
                                      expression: "formObj.Supplier"
                                    }
                                  },
                                  _vm._l(_vm.SupplierData, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.supplierName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v(_vm._s(_vm.$t("Buyer")))]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Po"),
                                  prop: "Po",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.Po
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64" },
                                  model: {
                                    value: _vm.formObj.Po,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "Po", $$v)
                                    },
                                    expression: "formObj.Po"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Purchase_Contract"),
                                  prop: "ContractId",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_ContractId",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.formObj.ContractId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formObj, "ContractId", $$v)
                                      },
                                      expression: "formObj.ContractId"
                                    }
                                  },
                                  _vm._l(_vm.ContractData, function(item) {
                                    return _c("el-option", {
                                      key: item.Id,
                                      attrs: {
                                        label: item.Code,
                                        value: item.Id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Brand_"),
                                  prop: "Brand",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64" },
                                  model: {
                                    value: _vm.formObj.Brand,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "Brand", $$v)
                                    },
                                    expression: "formObj.Brand"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Department"),
                                  prop: "DepartmentId",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_DepartmentId",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.formObj.DepartmentId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formObj,
                                          "DepartmentId",
                                          $$v
                                        )
                                      },
                                      expression: "formObj.DepartmentId"
                                    }
                                  },
                                  _vm._l(_vm.testData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Order_Date"),
                                  prop: "OrderDate",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.OrderDate
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    clearable: "",
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: " "
                                  },
                                  model: {
                                    value: _vm.formObj.OrderDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "OrderDate", $$v)
                                    },
                                    expression: "formObj.OrderDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("AC_Date"),
                                  prop: "AcDate",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.AcDate
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    clearable: "",
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: " "
                                  },
                                  model: {
                                    value: _vm.formObj.AcDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "AcDate", $$v)
                                    },
                                    expression: "formObj.AcDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-divider",
                      { attrs: { "content-position": "left" } },
                      [_vm._v(_vm._s(_vm.$t("Other")))]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Currency"),
                                  prop: "Currency",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.Currency
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_Currency",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    model: {
                                      value: _vm.formObj.Currency,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formObj, "Currency", $$v)
                                      },
                                      expression: "formObj.Currency"
                                    }
                                  },
                                  _vm._l(_vm.testData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Conversion_Rate"),
                                  prop: "ConversionRate",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.ConversionRate
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { "controls-position": "right" },
                                  model: {
                                    value: _vm.formObj.ConversionRate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formObj,
                                        "ConversionRate",
                                        $$v
                                      )
                                    },
                                    expression: "formObj.ConversionRate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Tax_Category"),
                                  prop: "TaxCategory",
                                  "label-width": "120px",
                                  rules: _vm.formObjRules.TaxCategory
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "Sd_TaxCategory",
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: ""
                                    },
                                    on: { change: _vm.Cg_TaxCategory },
                                    model: {
                                      value: _vm.formObj.TaxCategory,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formObj,
                                          "TaxCategory",
                                          $$v
                                        )
                                      },
                                      expression: "formObj.TaxCategory"
                                    }
                                  },
                                  _vm._l(_vm.testData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Tax_Rate"),
                                  prop: "TaxesAndCharges",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", disabled: true },
                                  model: {
                                    value: _vm.formObj.TaxesAndCharges,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formObj,
                                        "TaxesAndCharges",
                                        $$v
                                      )
                                    },
                                    expression: "formObj.TaxesAndCharges"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Tax_Total"),
                                  prop: "TotalTaxesAndCharges",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", disabled: true },
                                  model: {
                                    value: _vm.formObj.TotalTaxesAndCharges,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formObj,
                                        "TotalTaxesAndCharges",
                                        $$v
                                      )
                                    },
                                    expression: "formObj.TotalTaxesAndCharges"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Total"),
                                  prop: "Total",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", disabled: true },
                                  model: {
                                    value: _vm.formObj.Total,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "Total", $$v)
                                    },
                                    expression: "formObj.Total"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Set_The_Price_Algorithm"),
                                  prop: "IsIncludeTax",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-text": _vm.$t("Tax_Inclusive"),
                                    "inactive-text": _vm.$t("Tax_Exclusive")
                                  },
                                  on: { change: _vm.Cg_IsIncludeTax },
                                  model: {
                                    value: _vm.formObj.IsIncludeTax,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "IsIncludeTax", $$v)
                                    },
                                    expression: "formObj.IsIncludeTax"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Remark"),
                                  prop: "Remark",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64" },
                                  model: {
                                    value: _vm.formObj.Remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formObj, "Remark", $$v)
                                    },
                                    expression: "formObj.Remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "el-row_style" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "el-col_style",
                            attrs: { span: 12, sm: 24, md: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("In_Sys_NO"),
                                  prop: "HeadBusinessNoIn",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: { maxlength: "64", disabled: true },
                                  model: {
                                    value: _vm.formObj.HeadBusinessNoIn,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formObj,
                                        "HeadBusinessNoIn",
                                        $$v
                                      )
                                    },
                                    expression: "formObj.HeadBusinessNoIn"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("PO Lines")))
                    ]),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.formObj.itemTable,
                          "row-class-name": _vm.tableRowClassName,
                          height: "420",
                          "header-cell-class-name": _vm.starAdd
                        },
                        on: { "current-change": _vm.handleCurrentChange }
                      },
                      [
                        false
                          ? _c("el-table-column", [
                              _c("span", [_vm._v(_vm._s(_vm.scope.row.Id))])
                            ])
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px",
                            align: "center"
                          }
                        }),
                        !_vm.isLocked
                          ? _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("OPERATION"),
                                width: "110",
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("el-button", {
                                          attrs: {
                                            title: _vm.$t("Delete"),
                                            type: "danger",
                                            size: "small",
                                            icon: "el-icon-delete",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteItem(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        }),
                                        _c("el-button", {
                                          attrs: {
                                            title: _vm.$t("Add"),
                                            type: "primary",
                                            size: "small",
                                            icon: "el-icon-plus",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addItem(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                509828128
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("COST_CENTER"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.CostCenter))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".CostCenter",
                                        rules: _vm.formObjRules.CostCenter
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: scope.row.CostCenter,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "CostCenter",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.CostCenter"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("PRODUCT_CODE"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.ProductCode))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".ProductCode",
                                        rules: _vm.formObjRules.ProductCode
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_ProductCode(
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.ProductCode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "ProductCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.ProductCode"
                                              }
                                            },
                                            _vm._l(_vm.ProductData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("PRODUCT_NAME"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.ProductName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".ProductName"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              disabled: true
                                            },
                                            model: {
                                              value: scope.row.ProductName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "ProductName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.ProductName"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("COLOR_"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Color))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." + scope.$index + ".Color"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: scope.row.Color,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Color",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Color"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("DESCRIBE"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Description))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".Description"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: scope.row.Description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.Description"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("UNIT_"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.UomName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." + scope.$index + ".Uom",
                                        rules: _vm.formObjRules.Uom
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.Cg_Uom(scope.row)
                                                }
                                              },
                                              model: {
                                                value: scope.row.Uom,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "Uom",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.Uom"
                                              }
                                            },
                                            _vm._l(_vm.testData, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("QUANTITY_"),
                            width: "190",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Qty))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." + scope.$index + ".Qty",
                                        rules: _vm.formObjRules.Qty
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input-number", {
                                            attrs: {
                                              "controls-position": "right",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.Cg_RowAmount(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.Qty,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "Qty", $$v)
                                              },
                                              expression: "scope.row.Qty"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("UNIT_PRICE"),
                            width: "190",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Rate))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." + scope.$index + ".Rate",
                                        rules: _vm.formObjRules.Rate
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input-number", {
                                            attrs: {
                                              "controls-position": "right",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.Cg_RowAmount(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.Rate,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "Rate", $$v)
                                              },
                                              expression: "scope.row.Rate"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("TAX_TOTAL"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(scope.row.TotalTaxesAndCharges)
                                        )
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".TotalTaxesAndCharges"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              disabled: true
                                            },
                                            model: {
                                              value:
                                                scope.row.TotalTaxesAndCharges,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "TotalTaxesAndCharges",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.TotalTaxesAndCharges"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("TOTAL"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Amount))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".Amount"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              disabled: true
                                            },
                                            model: {
                                              value: scope.row.Amount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Amount",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Amount"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("REMARK"),
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Remark))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "itemTable." +
                                          scope.$index +
                                          ".Remark"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: scope.row.Remark,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Remark",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Remark"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        !_vm.isLocked
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.isSaveDisable
                                },
                                on: { click: _vm.submitForm }
                              },
                              [_vm._v(_vm._s(_vm.$t("Save")))]
                            )
                          : _vm._e(),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("Cancel")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }